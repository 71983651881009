<template>
  <div class="login">
    <div class="w1000">
      <div class="left">
        <div class="t1"></div>
        <div class="t2">NAAE</div>
        <div class="t3"><b>FIND</b> HOMESTAY</div>
        <div class="t4">
          <img src="@/assets/img/ip.png" alt="" />
          住的好 才能学的好
        </div>
        <div class="t5"></div>
      </div>
      <div class="right">
        <div class="title">
          欢迎访问NAAE寄宿家庭管理系统
        </div>
        <div class="codebox">
          <div id="code"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Login } from "@/api/login";
export default {
  data() {
    return {};
  },
  created() {
    console.log(this.$route.query.code);
    if (this.$route.query.code) {
      console.log("登录");
      this.login(this.$route.query.code);
    }
  },
  mounted() {
    this.getcode();
  },
  methods: {
    getcode() {
      const that = this;
      // var url = window.location.href
      var source = this.$route.query.source;
      var url = encodeURIComponent(`${window.location.origin}/#/login`);
      var appid = "dingoarh1ymbukgacqklyd";
      var goto = encodeURIComponent(
        `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${url}`
      );
      // eslint-disable-next-line no-undef
      var obj = DDLogin({
        id: "code", // 这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
        goto: goto, // 请参考注释里的方式
        style: "border:none;background-color:#FFFFFF;",
      });
      var handleMessage = function(event) {
        var origin = event.origin;
        if (origin == "https://login.dingtalk.com") {
          var loginTmpCode = event.data;
          if (loginTmpCode) {
            // // console.log("loginTmpCode", loginTmpCode);
            let u = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=${source}&redirect_uri=${url}&loginTmpCode=${loginTmpCode}`;
            window.location.href = u;
          }
        }
      };
      if (typeof window.addEventListener != "undefined") {
        window.addEventListener("message", handleMessage, false);
      } else if (typeof window.attachEvent != "undefined") {
        window.attachEvent("onmessage", handleMessage);
      }
    },
    login(code) {
      Login({
        code: code,
      }).then((res) => {
        if (res.code == 200) {
          // console.log(res);
          this.$cookie.set("token", res.data.token);
          this.$cookie.set("status", res.data.status); // 用户权限 1管理员2顾问
          this.$cookie.set("name", res.data.user.name);
          this.$cookie.set("avatar", res.data.user.avatar);
          this.$router.push("/layouts/list");
          this.$message({
            showClose: true,
            message: "登录成功！",
            type: "success",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background: url("../../assets/img/login_bck.png") no-repeat center;
  background-size: 100% 100%;
}
.w1000 {
  width: 1000px;
  height: 550px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  position: relative;
  .t1 {
    width: 172px;
    height: 79px;
    border: 3px solid white;
    border-bottom: none;
    margin-left: 66px;
  }
  .t2 {
    font-size: 87px;
    font-weight: 800;
    color: #ffffff;
    > b {
      color: #ff9b02;
    }
  }
  .t3 {
    font-size: 49px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 30px;
    > b {
      color: #ff9b02;
    }
  }
  .t4 {
    display: flex;
    align-items: center;
    font-size: 40px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: -15px;
    position: relative;
    z-index: 2;
    > img {
      height: 153px;
    }
  }
  .t5 {
    width: 172px;
    height: 79px;
    border: 3px solid white;
    border-top: none;
    margin-left: 66px;
  }
}
.right {
  width: 369px;
  height: 424px;
  background: rgba(255, 255, 255, 0.84);
  box-shadow: 0px 2px 43px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  .title {
    height: 74px;
    background: #079562;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 74px;
  }
  .codebox {
    background: white;
  }
}
</style>
